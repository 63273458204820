@import "./assets/scss/app.scss";

/********************************************************************************************/
/******* Below is the custom overide css project specific if not needed can be deleted ******/
/********************************************************************************************/
.logo-tagline{
  padding: 0.5rem 0.75rem;
  margin-top: 30px;
  font-size: 10px;
  font-style: italic;
  color: #e7e7e7;
}

ul.nav-menu {
  margin-top: 30px;
  padding-top: 0.4rem;
}

.nav-group-title {
  margin-top: 1.5rem;
  margin-bottom: 1.0rem;
}

.nav-menu-item {
  margin-bottom: 10px;
}

.nav-menu-item,
.nav-submenu-title,
.nav-menu-item,
.nav-submenu-title a,
.nav-submenu .nav-menu-item {
  line-height: 2.4rem;
  height: 2rem;
}

.side-nav.nav-menu-dark .nav-menu-item > a {
  padding: 0 0.5rem;
  margin: 0 0.75rem;
  border-radius: 5px;
}

.side-nav.nav-menu-dark .nav-menu-item > a:hover {
  background-color: $side-nav-dark-hover;
  color: #fff;
}

.side-nav.nav-menu-dark .nav-menu-item a.router-link-active {
  background-color: $primary;
  color: #fff;

  i {
    color: #fff;
  }

  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.side-nav.nav-menu-dark.nav-menu-collapse .nav-menu-item a.router-link-active {
  padding: 0;

  i {
    margin-left: 0.6rem;
  }
}

/*** Reduced height of header by 1 rem ***/

.side-nav .nav-logo {
  height: 3.375rem;
}

.header-nav {
  height: 3.375rem;
}

.vertical-layout .content {
  margin-top: 3.375rem;
}

/***********/

.form-control{
  padding: 0.5rem;
}

.form-row,
.form-group {
  margin-bottom: 15px;
}

table i.feather {
  &.icon-trash:hover {
    color: $danger;
  }
}

.tab-container {
  .nav-tabs {
    // padding: 0 1rem;
    padding-top: 1rem;
  }

  .tab-content {
    padding: 1.5rem 0;
  }
}

/* _print.scss:116 */
.custom-table.table-bordered {
  th,
  td {
    border: 1px solid $gray-300 !important;
  }
}

/* _tables.scss:48 */
.custom-table.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}

.custom-form {
  .form-heading {
    border-bottom: 1px solid $nav-tabs-border-color;
    padding-bottom: 0.25rem;
    margin-bottom: 1.5rem;
  }

  .form-content {
    padding-bottom: 7rem;
  }

  .form-actions {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    margin: 0 -1.5625rem;
    padding: 0 1.5625rem;
    background: #ffffff;
  }
}

.custom-details {
  .details-heading {
    // border-bottom: 1px solid $nav-tabs-border-color;
    // padding-bottom: 0.25rem;
    // margin-bottom: 1.5rem;
  }
}

//custom alert used for statuses
.custom-alert {
  display: inline-block;
  padding: 0.125rem 0.5rem;
  margin-bottom: 0px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.form-content .alert {
  ul {
    margin-bottom: 0px;
  }

  .btn-close:focus,
  .btn-close:active {
    outline: none;
  }
}

.modal-dialog.right {
  .modal-header {
    padding: 0.625rem 1rem;
  }
}

.settings {
  .columns-panel-item-link {
    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: #e0eefe;
    }

    &.active {
      background-color: #e0eefe;
      font-weight: 600;

      i {
        font-weight: 600;
      }
    }
  }

  .contents {
    margin-left: 1rem;
    border-bottom: 1px solid $border-color;
  }
}

.profile-picture-size {
  width: 80px;
  height: 80px;
}

.custom-avatar-empty {
  border: 1px dashed #f2f2f2;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.custom-avatar {
  position: relative;
  cursor: pointer;

  .overlay {
    position: absolute;
    background: #000;
    opacity: 0.8;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: none;
  }

  &:hover .overlay {
    display: flex;
  }

  img {
    width: 80px;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

table th span{
  &.sortable:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4xNDkgMC42NDY3NjhDMy4zNDM2NiAwLjQ1NDg4NSAzLjY1NjM0IDAuNDU0ODg1IDMuODUxIDAuNjQ2NzY4TDYuMTMxNTEgMi44OTQ2OUM2LjQ0OTk4IDMuMjA4NjIgNi4yMjc2OSAzLjc1MDc4IDUuNzgwNSAzLjc1MDc4SDEuMjE5NUMwLjc3MjMwNyAzLjc1MDc4IDAuNTUwMDE4IDMuMjA4NjIgMC44Njg0OTUgMi44OTQ2OUwzLjE0OSAwLjY0Njc2OFoiIGZpbGw9IiM2RTc4OTEiLz4KPHBhdGggZD0iTTMuMTQ5IDguMTU0MDFDMy4zNDM2NiA4LjM0NTkgMy42NTYzNCA4LjM0NTkgMy44NTEgOC4xNTQwMUw2LjEzMTUxIDUuOTA2MDlDNi40NDk5OCA1LjU5MjE2IDYuMjI3NjkgNS4wNSA1Ljc4MDUgNS4wNUgxLjIxOTVDMC43NzIzMDcgNS4wNSAwLjU1MDAxOCA1LjU5MjE2IDAuODY4NDk1IDUuOTA2MDlMMy4xNDkgOC4xNTQwMVoiIGZpbGw9IiM2RTc4OTEiLz4KPC9zdmc+Cg==);
    margin-left: 0.25rem;
    cursor: pointer;
  }

  &.sorted-asc:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4xNDkgMC42NDY3NjhDMy4zNDM2NiAwLjQ1NDg4NSAzLjY1NjM0IDAuNDU0ODg1IDMuODUxIDAuNjQ2NzY4TDYuMTMxNTEgMi44OTQ2OUM2LjQ0OTk4IDMuMjA4NjIgNi4yMjc2OSAzLjc1MDc4IDUuNzgwNSAzLjc1MDc4SDEuMjE5NUMwLjc3MjMwNyAzLjc1MDc4IDAuNTUwMDE4IDMuMjA4NjIgMC44Njg0OTUgMi44OTQ2OUwzLjE0OSAwLjY0Njc2OFoiIGZpbGw9IiM2RTc4OTEiLz4KPC9zdmc+Cg==);
    margin-left: 0.25rem;
    cursor: pointer;
  }

  &.sorted-desc:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4xNDkgOC4xNTQwMUMzLjM0MzY2IDguMzQ1OSAzLjY1NjM0IDguMzQ1OSAzLjg1MSA4LjE1NDAxTDYuMTMxNTEgNS45MDYwOUM2LjQ0OTk4IDUuNTkyMTYgNi4yMjc2OSA1LjA1IDUuNzgwNSA1LjA1SDEuMjE5NUMwLjc3MjMwNyA1LjA1IDAuNTUwMDE4IDUuNTkyMTYgMC44Njg0OTUgNS45MDYwOUwzLjE0OSA4LjE1NDAxWiIgZmlsbD0iIzZFNzg5MSIvPgo8L3N2Zz4K) !important;
    margin-top: -1px !important;
    margin-left: 0.25rem;
    cursor: pointer;
  }
}

table.loading > tbody {
  position: relative;
}

table.loading > tbody:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  opacity: 0.8;
  background-image: url('../src/assets/images/loader.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  content: "";
}

.text-small {
  font-size: 0.75rem;
}

.custom-disabled {
  opacity: 0.4;
  cursor: default !important;
}




/*
*** Loader css****
*/
.progress-dots i {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #9b9b9c;
  vertical-align: middle;
}

button .progress-dots {
  margin: 0;
  padding: 0;
}

button .progress-dots i {
  background: #fff;
}

.progress-dots i:first-child {
  transform: translate(-5px);
  animation: progress-dots2 0.5s linear infinite;
  opacity: 0;
}

.progress-dots i:nth-child(2),
.progress-dots i:nth-child(3) {
  animation: progress-dots3 0.5s linear infinite;
}

.progress-dots i:last-child {
  animation: progress-dots1 0.5s linear infinite;
}

@keyframes progress-dots1 {
  to {
    transform: translate(10px);
    opacity: 0;
  }
}

@keyframes progress-dots2 {
  to {
    transform: translate(5px);
    opacity: 1;
  }
}

@keyframes progress-dots3 {
  to {
    transform: translate(5px);
  }
}

// Quill Editor Css
.ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.ql-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.ql-editor {
  background-color: white !important;
  color: black !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

// To hide number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



/*
*****loader ends *
*/


/* later to be continued */

// .ngx-toastr {
//   background-color: #030303;
//   pointer-events: auto;
//   color: #030303 !important;
// }
// .toast-success {
//   background-color: #fff;
//   color: $gray-900;
//   color: #030303 !important;
//   border-left: 10px solid $success;
// }
// .toast-error {
//   background-color: #BD362F;
// }
// .toast-info {
//   background-color: #2F96B4;
// }
// .toast-warning {
//   background-color: #F89406;
// }

/*************/

.side-nav .nav-logo {
  
  padding: 0 0.75rem;
}