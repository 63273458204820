.popover {
    box-shadow: $popover-box-shadow;
}

// Temporary workaround for ngx bootstrap 9.0.0
.popover {
    position: absolute;
    top: 0;
    left: 0 #{"/* rtl:ignore */"};
}
    
.popover-arrow {
    position: absolute;
}